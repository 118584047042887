<template>
  <Button type="button" icon="pi pi-plus" label="Add" class="p-button-outlined" @click="openDialog()" />
  <Dialog 
    v-model:visible="showDialog" 
    :style="{ width: '90%' }" 
    header="Create Region" 
    :modal="true" 
    class="p-fluid"
    :maximizable="true" 
    @hide="closeDialog"
  >
    <div class="field">
      <label for="name">Name</label>
      <InputText 
        id="name" 
        v-model.trim="form.name" 
        @blur="v$.form.name.$touch" 
        required="true" 
        autofocus
        :class="{ 'p-invalid': !form.name }" 
      />
      <small class="p-error" v-if="!form.name">Name is required.</small>
    </div>

    <!-- <div class="field">
      <label for="name">Region code</label>
      <InputText 
        id="regionCode" 
        v-model.trim="form.regionCode" 
        @blur="v$.form.regionCode.$touch" 
        required="true"
        autofocus 
        :class="{ 'p-invalid': !form.regionCode }" 
      />
      <small class="p-error" v-if="!form.regionCode">Region code is required.</small>
    </div> -->

    <div class="field">
      <label for="name">Region type</label>

      <Dropdown 
        v-model="form.regionTypeId" 
        :options="regionTypes" 
        optionLabel="title" 
        optionValue="regionTypeId"
        placeholder="Region types" 
        required="true" 
        :class="{ 'p-invalid': !form.regionTypeId }" 
      />
      <small class="p-error" v-if="!form.regionTypeId">Region type is required.</small>
    </div>

    <div class="field">
      <label for="name">Countries</label>
      
      <div class="field m-3">
        <label for="name">Add country</label>
        <div class="d-flex">
          <Dropdown 
            v-model="newCountry" 
            :options="countries" 
            optionLabel="name" 
            optionValue="countryId" 
            :filter="true"
            :resetFilterOnHide="true"
            placeholder="Select a Country" 
            :showClear="true"
            style="width: 40%;"
          >
          </Dropdown>
          <Button 
            class="p-button-raised p-button-rounded p-button-primary ms-3"
            :icon="false ? 'pi pi-minus' : 'pi pi-plus'" 
            @click="addCountryToRegion()" 
          />
        </div>
      </div>

      <template v-for="country in childRegions" :key="country.countryId">
        <div class="field m-3 d-flex">
          <h5 style="display: grid; place-items: center;">{{country.name}}</h5>
          <Button label="" class="p-button-raised p-button-rounded p-button-danger ms-3" icon="pi pi-times"
            @click="deleteCountry(country)" />
        </div>
      </template>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
      <Button label="Save" icon="pi pi-check" class="p-button-text" :loading="createIsLoading"
        :disabled="this.v$.form.$invalid || createIsLoading" @click="create" />
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  name: 'CreateRegion',
  components: {
    Button,
    InputText,
    Dialog,
    Dropdown,
  },
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading: true,
      createIsLoading: false,
      showDialog: false,
      form: {
        name: null,
        regionCode: 'NA',
        regionTypeId: null,
        countries: null,
        isDefault: true,
      },
      newCountry: null,
      childRegions: [],
      filter: null
    };
  },
  validations() {
    return {
      form: {
        name: { required, maxLength: maxLength(64) },
        regionCode: { required, maxLength: maxLength(2) },
        regionTypeId: { required },
      },
      newCountry: { required },
    };
  },
  async mounted() {
    await this.getRegionTypes();
    await this.getCountries();
    this.initFilters();
  },
  computed: {
    regionTypes() {
      return this.$store.getters.obtainRegionTypes;
    },
    countries() {
      return this.$store.getters.obtainCountries;
    },
  },
  methods: {
    initFilters() {
      this.filter = this.countries
    },
    change(){
      console.log('change');
    },
    async getRegionTypes() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainRegionTypes');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    async getCountries() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainCountries');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    openDialog() {
      this.showDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
      this.form = {
        name: null,
        regionCode: null,
        regionTypeId: null,
      };
      this.newCountry= null;
      this.childRegions = [];
    },
    async create() {
      try {
        if (!this.v$.form.$invalid) {
          this.createIsLoading = true;
          this.form.countries = this.childRegions;
          await this.$store.dispatch('addNewRegion', this.form);
          this.createIsLoading = false;
          // toast.success('Successfully created.');
          this.closeDialog();
        } else {
          toast.error('Wrong region data.');
        }
      } catch (error) {
        this.createIsLoading = false;
        toast.error(error.message);
      }
    },
    addCountryToRegion() {
      const country = this.countries.find((c) => c.countryId == this.newCountry);
      if (country) {
        this.childRegions.push(country);
        this.newCountry = null;
      }
    },
    deleteCountry(country) {
      this.childRegions = this.childRegions.filter(
        (cr) => cr.countryId != country.countryId
      );
    },
    updateFormWithCountry(countryId) {
      console.log(countryId);
      console.log(this.childRegions);
    },
  },
};
</script>
