<template>
  <div class="card">
    <DataTable 
      :value="regions" 
      :loading="loading" 
      responsiveLayout="scroll" 
      showGridlines 
      :paginator="true"

      :rows="rowsPagValue"
      :rowsPerPageOptions="[10, 20, 50]" 
      :rowHover="true" 
      :first="firstPagValue"
      
      
      removableSort 
      dataKey="id"
      paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
      v-model:filters="filters" 
      @row-click="open"
      filterDisplay="menu" 
      :sort-field="sortFieldValue" 
      :sort-order="sortOrderValue" 
      @sort="onSort($event)"
      @page="onSort($event)"
    >
      <template #header>
        <h5 class="mb-2 md:m-0 p-as-md-center">Regions</h5>
        <div class="table-header row gy-3 d-flex flex-row justify-content-between">
          <div class="col-sm-12 col-md-2 d-flex flex-row justify-content-md-start">
            <CreateRegion />
          </div>

          <div class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start">
            <Dropdown v-model="selectedRegionTypeId" :options="[{ title: 'All', regionTypeId: null }, ...regionTypes]"
              optionLabel="title" optionValue="regionTypeId" placeholder="Filter by region types" />
          </div>

          <div class="col-sm-12 col-md-6">
            <div class="d-flex flex-row justify-content-md-end">
              <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined me-4"
                @click="clearFilter()" />
              <span class="p-input-icon-left" style="position: relative;">
                <i class="pi pi-search"  style="position: absolute; left: 5%; top:50%" />
                <InputText 
                  v-model="filters['global'].value" 
                  placeholder="Keyword Search" 
                  class="keywordSearch" 
                />
              </span>
            </div>
          </div>
        </div>
      </template>

      <Column v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" :sortable="true">
      </Column>

      <Column :field="'regionTypeId'" :header="'Region Type'" :key="'regionTypeId'" :sortable="true">
        <template #body="slotProps">
          {{ getRegionTypeNameById(slotProps.data.regionTypeId) }}
        </template>
      </Column>

      <Column :exportable="false" style="width: 10rem">
        <template #body="slotProps">
          <!-- <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success mx-2"
            @click="openEditDialog(slotProps.data)"
          /> -->
          <div style="display: grid; place-items: center;">
            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
              @click="openDeleteDialog(slotProps.data)" />
          </div>
        </template>
      </Column>

      <template #paginatorstart>
        <Button type="button" icon="pi pi-refresh" class="p-button-text" @click="getRegions(lastFilter)" />
      </template>
    </DataTable>
  </div>

  <DeleteRegion :selectedElement="selectedDeleteRow" @closeDialog="closeDialog($event)" />

  <EditRegion :selectedElement="selectedEditRow" @closeDialog="closeDialog($event)" />
</template>

<script>
import CreateRegion from './CreateRegion.vue';
import DeleteRegion from './DeleteRegion.vue';
import EditRegion from './EditRegion.vue';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  name: 'RegionList',
  components: {
    DataTable,
    Column,
    Button,
    InputText,
    Dropdown,
    CreateRegion,
    DeleteRegion,
    EditRegion,
  },
  data() {
    return {
      loading: true,
      createTopicIsLoading: false,
      editTopicIsLoading: false,
      deleteTopicIsLoading: false,
      filters: null,
      createDialog: false,
      selectedEditRow: null,
      selectedDeleteRow: null,
      selectedRegionTypeId: null,
      lastFilter: null,
      //Sortvalues
      sortFieldValue: null,
      sortOrderValue: null,
      //paginationCountValues
      firstPagValue: null,
      rowsPagValue:50,
    };
  },
  created() {
    this.columns = [
      { field: 'name', header: 'Name' },
      // { field: 'regionCode', header: 'Region Code' },
    ];
    this.initFilters();
  },
  async mounted() {
    await this.getRegions();
    await this.getRegionTypes();
    this.setAdminRegionSort();
  },
  computed: {
    regions() {
      // return this.$store.getters.obtainNotDefaultRegion;
      return this.$store.getters.obtainRegions
    },
    regionTypes() {
      return this.$store.getters.obtainRegionTypes.filter(item => item.regionTypeId != 4);
    },
    adminRegionSort() {
      return this.$store.getters.obtainAdminRegionSort;
    }
  },
  methods: {
    async getRegions(regionTypeId = null) {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainRegions', { regionTypeId, withChildren: true, isDefault: true });
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    async getRegionTypes() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainRegionTypes');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    setAdminRegionSort(){
      try {
        this.loading = true
        //sort
        this.sortFieldValue = this.adminRegionSort.sortField; 
        this.sortOrderValue = this.adminRegionSort.sortOrder;

        //pag
        if(this.adminRegionSort.firstPagValue === null){
          this.firstPagValue = 0;
        }
        else{
          this.firstPagValue = this.adminRegionSort.firstPagValue;
        }
        if(this.adminRegionSort.rowsPagValue === null){
          this.rowsPagValue = 50;
        }
        else{
          this.rowsPagValue = this.adminRegionSort.rowsPagValue
        }
        this.loading = false 
      } catch (error) {
        console.log(error);
      }
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    clearFilter() {
      this.initFilters();
    },
    openCreateDialog() {
      this.createDialog = true;
    },
    openEditDialog(element) {
      this.selectedEditRow = element;
    },
    open(event) {
      this.selectedEditRow = event.data;
    },
    openDeleteDialog(element) {
      this.selectedDeleteRow = element;
    },
    closeDialog($event) {
      if ($event == 'edit') {
        this.getRegions(this.lastFilter);
      }
      this.selectedEditRow = null;
      this.selectedDeleteRow = null;
      this.createDialog = false;
    },
    getRegionTypeNameById(regionTypeId) {
      const result = this.regionTypes.find(
        (d) => d.regionTypeId === regionTypeId
      );
      return result ? result.title : '';
    },

    // sort Functions 
    onSort(e) {
      const adminRegionSort = {
        sortField: e.sortField,
        sortOrder: e.sortOrder,
        firstPagValue: e.first,
        rowsPagValue: e.rows
      }
      this.$store.dispatch('updateAdminRegionSort', adminRegionSort);
    }
  },
  watch: {
    selectedRegionTypeId(newVal) {
      this.lastFilter = newVal;
      this.getRegions(newVal);
    },
  }
};
</script>

<style scoped>
.keywordSearch {
  /* padding: 0 0 0 2.5rem !important; */
  padding: 0.2rem 0 0.2rem 2.5rem  !important;
}
</style>