<template>
  <div class="container">
    <div class="row">
      <div class="col-12 py-4">
        <RegionList />
      </div>
    </div>
  </div>
</template>
<script>
import RegionList from './components/RegionsList.vue';

export default {
  name: 'Region',
  components: {
    RegionList,
  },
};
</script>
