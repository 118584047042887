<template>
  <!-- Edit Region -->
  <Dialog v-model:visible="showDialog" :style="{ width: '90%' }" header="Edit Region" :modal="true" class="p-fluid"
    :maximizable="true" @hide="closeDialog">
    <div class="field">
      <label for="name">Name</label>
      <InputText id="name" v-model.trim="form.name" @blur="v$.form.name.$touch" required="true" autofocus
        :class="{ 'p-invalid': !form.name }" />
      <small class="p-error" v-if="v$.form.name.$error">Name is required.</small>
    </div>

    <!-- <div class="field">
      <label for="name">Region code</label>
      <InputText
        id="regionCode"
        v-model.trim="form.regionCode"
        @blur="v$.form.regionCode.$touch"
        required="true"
        autofocus
        :class="{ 'p-invalid': !form.regionCode }"
      />
      <small class="p-error" v-if="v$.form.regionCode.$error"
        >Region code max length is 2.</small
      >
    </div> -->

    <div class="field">
      <label for="name">Region type</label>

      <Dropdown v-model="form.regionTypeId" :options="regionTypes" optionLabel="title" optionValue="regionTypeId"
        placeholder="Region types" required="true" :class="{ 'p-invalid': !form.regionTypeId }" />
      <small class="p-error" v-if="!form.regionTypeId">Region type is required.</small>
    </div>

    <div class="field">
      <h5 class="mt-3">Countries</h5>

      <div class="field m-3">
        <label for="name">Add country</label>
        <div class="d-flex">
          <Dropdown v-model="newCountry" :options="countries" optionLabel="name" optionValue="countryId" :filter="true"
            placeholder="Select a Country" :showClear="true" style="width: 40%;" :resetFilterOnHide="true">
          </Dropdown>

          <Button class="p-button-raised p-button-rounded p-button-primary ms-3"
            :icon="false ? 'pi pi-minus' : 'pi pi-plus'" @click="addCountryToRegion()" />
        </div>
      </div>

      <template v-for="country in childRegions" :key="country.countryId">
        <div class="field m-3 d-flex">
          <div v-if="country.country">
            <h4>{{country.country.name}}</h4>
          </div>
          <div v-else>
            <h4>{{country.name}}</h4>
          </div>

          <!-- <Dropdown v-model="country.countryId" :options="countries" optionLabel="name" optionValue="countryId"
            :filter="true" placeholder="Select a Country"  @change="updateFormWithCountry"
            style="width: 100%;">
          </Dropdown> -->

          <Button label="" class="p-button-raised p-button-rounded p-button-danger ms-3" icon="pi pi-times"
            @click="deleteCountry(country)" />
        </div>
      </template>
    </div>

    <template #footer>
      <Button label="Cancel" icon="pi pi-times" class="p-button-text" @click="closeDialog" />
      <Button label="Save" icon="pi pi-check" class="p-button-text" :loading="editIsLoading"
        :disabled="this.v$.form.$invalid || editIsLoading" @click="edit" />
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import useVuelidate from '@vuelidate/core';
import { required, maxLength } from '@vuelidate/validators';
import { useToast } from 'vue-toastification';
const toast = useToast();

export default {
  name: 'EditRegion',
  components: {
    Button,
    InputText,
    Dialog,
    Dropdown,
  },
  props: ['selectedElement'],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      loading: true,
      editIsLoading: false,
      showDialog: false,
      form: {
        name: null,
        regionCode: null,
        regionTypeId: null,
        countries: [],
      },
      newCountry: null,
      childRegions: [],
    };
  },
  validations() {
    return {
      form: {
        name: { required, maxLength: maxLength(64) },
        regionCode: { required, maxLength: maxLength(2) },
        regionTypeId: { required },
      },
      newCountry: { required },
    };
  },
  async mounted() {
    await this.getRegionTypes();
  },
  computed: {
    regionTypes() {
      return this.$store.getters.obtainRegionTypes;
    },
    countries() {
      return this.$store.getters.obtainCountries;
    },
  },
  methods: {
    async getRegionTypes() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainRegionTypes');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    async getCountries() {
      try {
        this.loading = true;
        await this.$store.dispatch('obtainCountries');
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },
    closeDialog() {
      this.showDialog = false;
      this.form = {
        name: null,
        regionCode: null,
        regionTypeId: null,
      };
      this.$emit('closeDialog', 'edit');
    },
    async edit() {
      try {
        if (!this.v$.form.$invalid) {
          this.editIsLoading = true;
          // this.form.countries = this.childRegions;
          // this.form.regionCountries = this.childRegions;
          // console.log(this.childRegions);
          const contries = this.childRegions.map(childRegion => {
            if (childRegion.country) {
              return {
                "countryId": childRegion.country.countryId,
                "countryCode": childRegion.country.countryCode,
                "name": childRegion.country.name
              }
            }
            else{
              return {
                "countryId": childRegion.countryId,
                "countryCode": childRegion.countryCode,
                "name": childRegion.name
              }
            }
          })
          // console.log(contries);
          this.form.countries = contries;
          await this.$store.dispatch('modifyRegion', {
            regionId: this.selectedElement.regionId,
            ...this.form,
          });
          this.editIsLoading = false;
          // toast.success('Successfully updated.');
          this.closeDialog();
        } else {
          toast.error('Wrong region data.');
        }
      } catch (error) {
        this.editIsLoading = false;
        toast.error(error.message);
      }
    },
    addCountryToRegion() {
      const country = this.countries.find((c) => c.countryId == this.newCountry);
      if (country) {
        this.childRegions.push(country);
        this.newCountry = null;
      }
    },
    deleteCountry(country) {
      this.childRegions = this.childRegions.filter(
        (cr) => cr.countryId != country.countryId
      );
    },
    updateFormWithCountry(countryId) {
      console.log(countryId);
      console.log(this.childRegions);
    },
  },
  watch: {
    async selectedElement(newVal) {
      if (newVal) {
        this.showDialog = true;
        this.form = Object.assign({}, this.selectedElement);
        const sort = this.form.regionCountries.sort((a, b) => a.country.name.localeCompare(b.country.name))
        // console.log(sort);
        // this.childRegions = this.form.regionCountries;
        this.childRegions = sort;
        await this.getCountries();
      }
    },
  },
};
</script>
