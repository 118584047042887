<template>
  <Dialog
    v-model:visible="showDialog"
    :style="{ width: '450px' }"
    header="Delete Region"
    :modal="true"
    @hide="closeDialog"
  >
    <div class="confirmation-content d-flex flex-row">
      <i class="pi pi-exclamation-triangle me-3" style="font-size: 2rem" />
      <span
        >Are you sure you want to delete
        <span class="fw-bold">{{ selectedElement.name }}</span>
      </span>
    </div>
    <template #footer>
      <Button
        label="No"
        icon="pi pi-times"
        class="p-button-text"
        @click="closeDialog"
      />
      <Button
        label="Yes"
        icon="pi pi-check"
        class="p-button-text"
        :loading="deleteIsLoading"
        :disabled="deleteIsLoading"
        @click="deleteElement"
      />
    </template>
  </Dialog>
</template>

<script>
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';

export default {
  name: 'DeleteRegion',
  components: {
    Button,
    Dialog,
  },
  props: ['selectedElement'],
  data() {
    return {
      loading: true,
      deleteIsLoading: false,
      showDialog: false,
    };
  },
  methods: {
    closeDialog() {
      this.showDialog = false;
      this.$emit('closeDialog', 'delete');
    },
    async deleteElement() {
      try {
        this.deleteIsLoading = true;
        await this.$store.dispatch('removeRegion', this.selectedElement.regionId);
        this.deleteIsLoading = false;
        this.closeDialog();
      } catch (error) {
        this.deleteIsLoading = false;
      }
    },
  },
  watch: {
    selectedElement(newVal) {
      if (newVal) {
        this.showDialog = true;
      }
    },
  },
};
</script>
